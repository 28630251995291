import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import { items, ServiceItem } from "../HomePage/sections/Services";
import TopNav from "../../common/TopNav";
import Footer from "../../common/Footer";

import "./styles.scss";

const ServicesPage = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ padding: "0 15%" }}
      className="services-page"
    >
      <Stack.Item grow className="withLayout">
        <TopNav />
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <div className="header">
          <div className="title">
            <h1>Services</h1>
          </div>
          {/* <div className="summary">
            BRIC LLC focuses on the Medical, Staffing and Government
            Contracting sectors. Government contracts can be local, state or
            federal. Instead of waiting to get paid, you can get quick access in
            as little as 24 hours.
          </div> */}
        </div>
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <Stack className="page-content">
          {items.map((item, i) => (
            <ServiceItem key={i} {...item} />
          ))}
        </Stack>
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <Footer />
      </Stack.Item>
    </Stack>
  );
};

export default ServicesPage;
