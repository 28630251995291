import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";

import "./styles.scss";

export interface IValueItem {
  id: number;
  title: string;
  image: string;
  content: string;
}

export const items: IValueItem[] = [
  {
    id: 1,
    title: "Transparency",
    image: "image1",
    content:
      "We keep our communication channels and our business practices open and honest.",
  },
  {
    id: 2,
    title: "Value",
    image: "image2",
    content:
      "We believe in fully understanding your needs so you get the most meaningful value from our services.",
  },
  {
    id: 3,
    title: "Committment",
    image: "image3",
    content:
      "We are passionate about enabling organizations to succeed and are committed to providing you value.",
  },
];

const ValueCardItem: React.FC<IValueItem> = ({ title, image, content }) => (
  <Stack horizontal className="card-item">
    <Stack.Item grow>
      <div className={`card-image ${image}`}></div>
    </Stack.Item>
    <Stack.Item grow>
      <div className="content">
        <h4>{title}</h4>
        <p>{content}</p>
      </div>
    </Stack.Item>
  </Stack>
);

const ValuesSection = () => (
  <section className="values-section">
    <Stack horizontal tokens={{ childrenGap: 60 }}>
      <Stack.Item grow styles={{ root: { width: "50%" } }}>
        <Stack tokens={{ childrenGap: 60 }}>
          <Stack.Item grow>
            <div className="section-intro">
              <h3>BRIC Values</h3>
              <p>
                In today's challenging global marketplace, clients require a
                committed, trustworthy, and professional financing company. Here
                at BRIC, we lead by our core values:
              </p>
            </div>
          </Stack.Item>
          <Stack.Item grow>
            <ValueCardItem {...items[1]} />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item grow styles={{ root: { width: "50%" } }}>
        <Stack tokens={{ childrenGap: 60 }}>
          <Stack.Item grow>
            <ValueCardItem {...items[0]} />
          </Stack.Item>
          <Stack.Item grow>
            <ValueCardItem {...items[2]} />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  </section>
);

export default ValuesSection;
