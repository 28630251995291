import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import TopNav from "../../common/TopNav";
import Footer from "../../common/Footer";

import "./styles.scss";

const ThankYouPage: React.FC = () => {
  // redirect
  React.useEffect(() => {
    window.setTimeout(() => {
      window.location.href = "/";
    }, 6000);
  });

  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ padding: "0 15%" }}
      className="apply-page"
    >
      <Stack.Item grow className="withLayout">
        <TopNav />
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <div className="header">
          <div className="title">
            <h1>Thank You</h1>
          </div>
          <div className="summary">
            <p>We appreciate your submission.</p>
            <p>
              You will be redirected to the homepage shortly. If you are not
              redirected, please <a href="/">click here</a>
            </p>
          </div>
        </div>
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <Footer />
      </Stack.Item>
    </Stack>
  );
};

export default ThankYouPage;
