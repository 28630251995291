import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import TopNav from "../../common/TopNav";
import Footer from "../../common/Footer";
import { items, IValueItem } from "../HomePage/sections/Values";

import "./styles.scss";

const SectionItem: React.FC<IValueItem> = ({ id, title, content, image }) => (
  <Stack.Item grow>
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      className={`item-container item${id}`}
    >
      <Stack.Item>
        <div className={`image ${image}`}></div>
      </Stack.Item>
      <Stack.Item grow>
        <div className="info">
          <h4>{title}</h4>
          <p>{content}</p>
        </div>
      </Stack.Item>
    </Stack>
  </Stack.Item>
);

const OurPromisePage = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ padding: "0 15%" }}
      className="ourpromise-page"
    >
      <Stack.Item grow className="withLayout">
        <TopNav />
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <div className="header">
          <div className="title">
            <h1>Our Promise</h1>
          </div>
          <div className="summary">
            <p>
              In today's challenging global marketplace, clients require a
              committed, trustworthy, and professional financing company. Here
              at BRIC, we lead by our core values.
            </p>
          </div>
        </div>
      </Stack.Item>
      <Stack.Item>
        {items.map((item, i) => (
          <SectionItem key={i} {...item} />
        ))}
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <Footer />
      </Stack.Item>
    </Stack>
  );
};

export default OurPromisePage;
