import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import TopNav from "../../common/TopNav";
import Footer from "../../common/Footer";
import { items, IItemProps } from "../HomePage/sections/HowItWorks";

import "./styles.scss";

const SectionItem: React.FC<IItemProps> = ({ id, title, content, icon }) => (
  <Stack.Item grow>
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      className={`item-container item${id}`}
    >
      <Stack.Item>
        <div className={`icon ${icon}`}></div>
      </Stack.Item>
      <Stack.Item grow>
        <div className="info">
          <h4>{title}</h4>
          <p>{content}</p>
        </div>
      </Stack.Item>
    </Stack>
  </Stack.Item>
);

const HowItWorksPage = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ padding: "0 15%" }}
      className="howitworks-page"
    >
      <Stack.Item grow className="withLayout">
        <TopNav />
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <div className="header">
          <div className="title">
            <h1>How It Works</h1>
          </div>
          <div className="summary">
            <p>
              When you choose BRIC as your financing provider, you get a team of
              dedicated financing professionals to help you through the process
              of establishing a lending solution. We make it easy for you to
              work with us!
            </p>
          </div>
        </div>
      </Stack.Item>
      <Stack.Item>
        <Stack>
          {items.map((item) => (
            <SectionItem {...item} />
          ))}
        </Stack>
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <Footer />
      </Stack.Item>
    </Stack>
  );
};

export default HowItWorksPage;
