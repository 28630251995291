import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { TextField, ITextFieldProps } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";

import "./styles.scss";

const renderCustomLabel = (props: any) => {
  let { id, label } = props as ITextFieldProps;
  return (
    <div id={id} className="fieldLabel">
      {label}
    </div>
  );
};

const ContactItem: React.FC<{ icon: any; content: string | JSX.Element }> = ({
  icon,
  content,
}) => {
  return (
    <div className="contact-container">
      <div className="icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="content">{content}</div>
    </div>
  );
};

const ContactUsSection: React.FC = () => {
  const [form, setForm] = React.useState<any>({});
  const updateState = (data: any) => setForm({ ...form, ...data });
  return (
    <section className="contactus-section">
      <div className="container">
        <Stack horizontal horizontalAlign="space-evenly">
          <Stack.Item grow>
            <div className="info">
              <h3>Contact Us</h3>
              <ol>
                <li>
                  <ContactItem
                    icon={faPhone}
                    content={<a href="tel:844-901-2742">(844) 901 - BRIC</a>}
                  />
                </li>
                <li>
                  <ContactItem
                    icon={faEnvelope}
                    content={
                      <a href="mailto:info@thebric.com">info@thebric.com</a>
                    }
                  />
                </li>
                {/* <li>
                <ContactItem
                  icon={faMapMarkerAlt}
                  content="10482 Baltimore Ave. Beltsville, MD 20705"
                />
              </li> */}
              </ol>
            </div>
          </Stack.Item>
          <Stack.Item grow>
            <form
              className="form"
              noValidate
              autoComplete="off"
              action="https://www.bricmedical.com/formtools/process.php"
              method="post"
            >
              <input type="hidden" name="form_tools_form_id" value="2" />
              <TextField
                label="Name"
                name="Name"
                className={`formField`}
                underlined
                onRenderLabel={renderCustomLabel}
                value={form.name}
                onChange={(e, value) => {
                  updateState({ name: value });
                }}
              />
              <TextField
                label="Email"
                name="Email"
                className={`formField`}
                underlined
                onRenderLabel={renderCustomLabel}
                value={form.email}
                onChange={(e, value) => {
                  updateState({ email: value });
                }}
              />
              <TextField
                label="Phone Number"
                name="Phone"
                className={`formField`}
                underlined
                onRenderLabel={renderCustomLabel}
                value={form.phonenumber}
                onChange={(e, value) => {
                  updateState({ phonenumber: value });
                }}
              />
              <Dropdown
                label="Service"
                options={[
                  { key: "Medical Services", text: "Medical Services" },
                  { key: "Staffing", text: "Staffing" },
                  {
                    key: "Government Contractors",
                    text: "Government Contractors",
                  },
                  {
                    key: "Other",
                    text: "Other (Please add info in Comments)",
                  },
                ]}
                className={`formField dropdown`}
                onRenderLabel={renderCustomLabel}
                selectedKey={form.service}
                onChange={(e, value) => {
                  updateState({ service: value?.key });
                }}
              />
              <input type="hidden" name="Service" value={form.service} />
              <TextField
                label="Comments"
                name="Comments"
                className={`formField comments`}
                underlined
                multiline
                onRenderLabel={renderCustomLabel}
                value={form.comments}
                onChange={(e, value) => {
                  updateState({ comments: value });
                }}
              />
              <DefaultButton
                type="submit"
                styles={{
                  root: { backgroundColor: "#193152", color: "#ffffff" },
                }}
              >
                Submit
              </DefaultButton>
            </form>
          </Stack.Item>
        </Stack>
      </div>
    </section>
  );
};

export default ContactUsSection;
