import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import {
  ThemeProvider,
  PartialTheme,
  createTheme,
} from "@fluentui/react/lib/Theme";

// App pages
import HomePage from "../pages/HomePage";
import HowItWorksPage from "../pages/HowItWorks";
import ServicesPage from "../pages/Services";
import OurPromisePage from "../pages/OurPromise";
import ApplyPage from "../pages/Apply";
import ThankYouPage from "../pages/ThankYou";

import "./fonts.scss";
import "./styles.scss";

initializeIcons();

const appTheme: PartialTheme = createTheme({
  defaultFontStyle: { fontFamily: "Red Hat Display" },
});

const App = () => {
  const ref = React.createRef<HTMLDivElement>();

  const [width, setWidth] = React.useState<number>(document.body.clientWidth);
  const [height, setHeight] = React.useState<number>(document.body.clientWidth);

  React.useEffect(() => {
    console.log(ref.current);
  }, [ref]);

  // add dimensions to app container
  React.useEffect(() => {
    const setDimensions = () => {
      setWidth(document.body.clientWidth);
      setHeight(document.body.clientHeight);
    };
    window.addEventListener("resize", setDimensions);
    return () => window.removeEventListener("resize", setDimensions);
  }, [width, height]);

  return (
    <ThemeProvider theme={appTheme}>
      <div
        ref={ref}
        className="app"
        // style={{ width: `${width}px`, height: `${height}px` }}
      >
        <div className="app-wrapper">
          <Switch>
            <Route exact path="/howitworks">
              <HowItWorksPage />
            </Route>
            <Route exact path="/services">
              <ServicesPage />
            </Route>
            <Route exact path="/promise">
              <OurPromisePage />
            </Route>
            <Route exact path="/apply">
              <ApplyPage />
            </Route>
            <Route exact path="/thankyou">
              <ThankYouPage />
            </Route>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
