import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";

// section components
import TopSection, { TopSectionBackground } from "./sections/Top";
import QuickInfoSection from "./sections/QuickInfo";
import ValuesSection from "./sections/Values";
import WhatIsSection from "./sections/WhatIs";
import HowItWorksSection from "./sections/HowItWorks";
import ServicesSection from "./sections/Services";
import ContactUsSection from "./sections/ContactUs";
import FooterSection from "../../common/Footer";

import "./styles.scss";

const HomePage = () => {
  return (
    <>
      <TopSectionBackground />
      <div className="homepage-wrapper">
        <Stack horizontalAlign="center" tokens={{ padding: "0 15%" }}>
          <Stack.Item grow className="withLayout">
            <Stack>
              <Stack.Item grow>
                <TopSection />
              </Stack.Item>
              <Stack.Item grow>
                <QuickInfoSection />
              </Stack.Item>
              <Stack.Item grow>
                <ValuesSection />
              </Stack.Item>
              <Stack.Item grow>
                <WhatIsSection />
              </Stack.Item>
              <Stack.Item grow>
                <HowItWorksSection />
              </Stack.Item>
              <Stack.Item grow>
                <ServicesSection />
              </Stack.Item>
              <Stack.Item grow>
                <ContactUsSection />
              </Stack.Item>
              <Stack.Item grow>
                <FooterSection />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </div>
    </>
  );
};

export default HomePage;
