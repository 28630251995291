import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import { Image } from "@fluentui/react/lib/Image";
import { DefaultButton } from "@fluentui/react/lib/Button";
import TopNav from "../../../../common/TopNav";

import globeImg from "../../../../../images/hero-globe.png";
import heroImg from "../../../../../images/hero-image.png";

import "./styles.scss";

const SectionBody = () => (
  <Stack horizontal horizontalAlign="space-between" className="section-body">
    <Stack.Item grow>
      <div className="hero-text">
        <div className="highlight">Medical</div>
        <div>Financing</div>
        <div className="highlight">Solutions</div>
        <div>For You</div>
      </div>
      <DefaultButton className="btnApplyToday" href="/apply">
        Apply Today
      </DefaultButton>
    </Stack.Item>
    <Stack.Item grow>
      <Image src={heroImg} alt="" className="hero-image" />
    </Stack.Item>
  </Stack>
);

export const TopSectionBackground = () => (
  <Stack horizontal>
    <Stack.Item
      grow={1.5}
      styles={{
        root: {
          background: `url(${globeImg}) #f9fcff no-repeat 75% 50%`,
          height: 600,
        },
      }}
    >
      &nbsp;
    </Stack.Item>
    <Stack.Item grow styles={{ root: { backgroundColor: "#193152" } }}>
      &nbsp;
    </Stack.Item>
  </Stack>
);

const TopSection = () => (
  <section className="top-section">
    <Stack className="section-content">
      <Stack.Item>
        <TopNav isHomePage />
        <SectionBody />
      </Stack.Item>
    </Stack>
  </section>
);

export default TopSection;
