import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { DefaultButton } from "@fluentui/react/lib/Button";
import TopNav from "../../common/TopNav";
import Footer from "../../common/Footer";

import "./styles.scss";

const ApplyForm: React.FC = () => {
  const [form, setForm] = React.useState<any>({});
  const updateState = (data: any) => setForm({ ...form, ...data });
  return (
    <form
      className="form"
      noValidate
      autoComplete="off"
      action="https://www.bricmedical.com/formtools/process.php"
      method="post"
    >
      <input type="hidden" name="form_tools_form_id" value="1" />
      <Dropdown
        label="How Did You Hear About Us?"
        placeholder="Select an option"
        className={`formField dropdown`}
        selectedKey={form.hearaboutus}
        onChange={(e, value) => {
          updateState({ hearaboutus: value?.key });
        }}
        options={[
          { key: "Website", text: "Website" },
          { key: "LinkedIn", text: "LinkedIn" },
          { key: "Facebook", text: "Facebook" },
          { key: "Other", text: "Other" },
        ]}
      />
      <input type="hidden" name="HearAboutUs" value={form.hearaboutus} />
      <TextField
        label="First Name"
        name="FirstName"
        className={`formField`}
        value={form.firstname}
        onChange={(e, value) => {
          updateState({ firstname: value });
        }}
      />
      <TextField
        label="Last Name"
        name="LastName"
        className={`formField`}
        value={form.lastname}
        onChange={(e, value) => {
          updateState({ lastname: value });
        }}
      />
      <TextField
        label="Phone Number"
        name="PhoneNumber"
        className={`formField`}
        value={form.phonenumber}
        onChange={(e, value) => {
          updateState({ phonenumber: value });
        }}
      />
      <TextField
        label="Email"
        name="Email"
        className={`formField`}
        value={form.email}
        onChange={(e, value) => {
          updateState({ email: value });
        }}
      />
      <Dropdown
        label="Loan Type"
        placeholder="Select an option"
        className={`formField dropdown`}
        options={[
          { key: "Medical Services", text: "Medical Services" },
          { key: "Staffing", text: "Staffing" },
          {
            key: "Government Contractors",
            text: "Government Contractors",
          },
          {
            key: "Other",
            text: "Other (Please add info in Scenario)",
          },
        ]}
        selectedKey={form.loantype}
        onChange={(e, value) => {
          updateState({ loantype: value?.key });
        }}
      />
      <input type="hidden" name="LoanType" value={form.loantype} />
      <TextField
        label="Desired Funding Amount"
        name="DesiredFundingAmount"
        className={`formField`}
        value={form.fundingamount}
        onChange={(e, value) => {
          updateState({ fundingamount: value });
        }}
      />
      <TextField
        label="Scenario"
        name="Scenario"
        className={`formField`}
        multiline
        value={form.scenario}
        onChange={(e, value) => {
          updateState({ scenario: value });
        }}
      />
      <div className="btnSubmit">
        <DefaultButton type="submit" text="Submit" />
      </div>
    </form>
  );
};

const ApplyPage = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ padding: "0 15%" }}
      className="apply-page"
    >
      <Stack.Item grow className="withLayout">
        <TopNav />
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <div className="header">
          <div className="title">
            <h1>Apply</h1>
          </div>
          <div className="summary">
            <p>
              Fill out the form below and one of our Financing Specialists will
              get in touch with you.
            </p>
          </div>
        </div>
        <ApplyForm />
      </Stack.Item>
      <Stack.Item grow className="withLayout">
        <Footer />
      </Stack.Item>
    </Stack>
  );
};

export default ApplyPage;
