import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "@fluentui/react/lib/Stack";
import { Image } from "@fluentui/react/lib/Image";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";

import logoImg from "../../../images/logo.png";
import "./styles.scss";

const ButtonPhoneNumber = () => (
  <div className="navPhone">
    <div className="phoneIcon">
      <FontAwesomeIcon icon={faPhone} />
    </div>
    <div>844-901-BRIC</div>
  </div>
);

const ButtonApplyNow = () => (
  <DefaultButton className="btnNavApplyNow" href="/apply">
    Apply Now
  </DefaultButton>
);

export interface ITopNavProps {
  isHomePage?: boolean;
}

const TopNav: React.FC<ITopNavProps> = ({ isHomePage }) => {
  return (
    <>
      <Stack
        className={`topNav ${!isHomePage ? "page" : ""}`}
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item>
          <Link to="/">
            <Image src={logoImg} alt="logo" style={{ maxWidth: "50%" }} />
          </Link>
        </Stack.Item>
        <Stack.Item>
          <Link to="/howitworks" className="navLink">
            How It Works
          </Link>
        </Stack.Item>
        <Stack.Item>
          <Link to="/services" className="navLink">
            Services
          </Link>
        </Stack.Item>
        <Stack.Item>
          <Link to="/promise" className="navLink">
            Our Promise
          </Link>
        </Stack.Item>
        <Stack.Item>
          <a href="tel:844-901-2742" className="navLink">
            <ButtonPhoneNumber />
          </a>
        </Stack.Item>
        <Stack.Item>
          <ButtonApplyNow />
        </Stack.Item>
      </Stack>
    </>
  );
};

export default TopNav;
