import * as React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import { Image } from "@fluentui/react/lib/Image";
import { Link } from "@fluentui/react/lib/Link";

import logoImg from "../../../images/logo.png";

import "./styles.scss";

interface ISiteMapData {
  title: string;
  items: { name: string; url?: string }[];
}

const data: ISiteMapData[] = [
  {
    title: "Menu",
    items: [
      {
        name: "Services",
        url: "/services",
      },
      {
        name: "Our Promise",
        url: "/promise",
      },
    ],
  },
  {
    title: "Financing",
    items: [
      {
        name: "How It Works",
        url: "/howitworks",
      },
      // {
      //   name: "Rates",
      //   url: "/rates",
      // },
    ],
  },
  {
    title: "Hours of Operation",
    items: [
      { name: "Monday,9 AM - 5 PM" },
      { name: "Tuesday,9 AM - 5 PM" },
      { name: "Wednesday,9 AM - 5 PM" },
      { name: "Thursday,9 AM - 5 PM" },
      { name: "Friday,9 AM - 5 PM" },
      { name: "Saturday,Closed" },
      { name: "Sunday,Closed" },
    ],
  },
];

const formatData = (data: string) => {
  let parts = data.split(",");
  let day = parts[0];
  let hours = parts[1];

  return (
    <div className="hours">
      <div>{day}:</div>
      <div>{hours}</div>
    </div>
  );
};

const Footer = () => (
  <section className="footer">
    <Stack>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item grow>
            <div>
              <Image src={logoImg} alt="logo" className="logo" />
              {/* <div className="footer-text">
                The BRIC, LLC focuses on providing financing solution for
                organizations across a rnage of industries anywhere in the U.S.
                The BRIC, LLC is headquartered in Lanham, MD with regional
                offices throughout the nation.
              </div> */}
            </div>
          </Stack.Item>
          {data.map(({ title, items }, i) => (
            <Stack.Item key={i} grow>
              <div className="sitemap-item">
                <h4>{title}</h4>
                <ol>
                  {items.map(({ name, url }, k) => {
                    return (
                      <li key={k}>
                        {url ? (
                          <Link href={url}>{name}</Link>
                        ) : (
                          formatData(name)
                        )}
                      </li>
                    );
                  })}
                </ol>
              </div>
            </Stack.Item>
          ))}
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <div className="legal">
          <div className="copyright">
            &copy; 2022 BRIC, LLC. All rights reserved.
          </div>
          <div className="terms">
            <Link href="/terms">Terms of Service</Link> |{" "}
            <Link href="/privacy">Privacy Policy</Link>
          </div>
        </div>
      </Stack.Item>
    </Stack>
  </section>
);

export default Footer;
