import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";

import "./styles.scss";

const WhatIsSection = () => (
  <section className="whatis-section">
    <Stack horizontal>
      <Stack tokens={{ maxWidth: "50%" }}>
        <Stack.Item>
          <div>
            <h3>What is Medical Financing?</h3>
            <p>
              A Medical Providers Biggest Asset is typically the Real Estate
              owned or the Account Receivables (A/R), the payments due from
              Medicare/Medicaid, HMO's, Private Insurance etc. Accessing this
              capital is the Medical Providers Biggest Assets and is how BRIC
              MEDICAL serves Medical Providers/Professionals. We at BRIC MEDICAL
              focus on providing Real Estate Mortgages and Factoring to provide
              liquidity and financing options for a range of Medical Providers.
            </p>
          </div>
        </Stack.Item>
      </Stack>
      <Stack
        tokens={{ maxWidth: "50%" }}
        styles={{ root: { margin: "0 auto !important" } }}
      >
        <Stack.Item grow>
          <div className="image-container">
            <div className="image"></div>
          </div>
        </Stack.Item>
      </Stack>
    </Stack>
  </section>
);

export default WhatIsSection;
