import React, { ReactElement } from "react";
import { Stack } from "@fluentui/react/lib/Stack";

import "./styles.scss";

export const items = [
  {
    id: 1,
    image: "image1",
    title: "Medical Real Estate Financing",
    subtitle: "",
    body: () => (
      <div className="card1">
        <p>
          Real Estate ownership is often the cornerstone of our Medical
          Providers Organizations. BRIC MEDICAL can assist with either a
          purchase of a Medical office or a refinancing to a better position via
          cash out (to grow the Medical Practice), rate reduction or
          Consolidation of debt to name a few. BRIC MEDICAL offers several types
          of Commercial Financing on a range of property types, from office
          Condo's to office Parks, from "Stated" financing to SBA from
          Conventional to Alt A. We will work to determine the best fit for your
          Commercial Financing Needs.
        </p>
        <div>
          <h4>Most common Health Care Providers Serviced</h4>
          <ul>
            {[
              "Urgent Care Centers",
              "Nursing Homes",
              "Wellness Centers",
              "Funeral Homes",
              "Assistant Living",
              "Medical Offices",
            ].map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    ),
  },
  {
    id: 2,
    image: "image2",
    title: "Medical Account Receivable Financing",
    subtitle: "",
    body: () => (
      <div className="card2">
        <p>
          Medical Factoring is a type of invoice Factoring where a Financial
          Institution (Factor) provides a Medical provider with an advance
          payment based on providers outstanding account receivables (invoices).
          Medical Factors will consider any provider that bills third party
          insurance carriers. BRIC LLC focuses on the Medical, Staffing and
          Government Contracting sectors. Government contracts can be local,
          state or federal. Instead of waiting to get paid, you can get quick
          access in as little as 24 hours.
        </p>
        <div>
          <h4>Most common Health Care Providers Serviced</h4>
          <ul>
            {[
              "Home Health Agencies",
              "Drug Rehabilitation Centers",
              "Specialty Pharmacies",
              "Imaging Centers",
              "Walk-in Clinics",
              "Medical Transport",
              "Group Homes (Adults/Children)",
              "Individual $ Group Medical Practices",
            ].map((item, k) => (
              <li key={k}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    ),
  },
  {
    id: 3,
    image: "image3",
    title: "Essential Use Medical Equipment Financing",
    subtitle: "",
    body: () => (
      <>
        <p>
          Financing solutions for essential medical use. Typically due to
          inefficiencies in the Health Care system, Medical providers often wait
          15-30 days to collect on services rendered. These inefficiencies limit
          the providers ability to conduct and grow their business. In many
          cases, we are able, within 24 hours of approval, to provide the
          liquidity requested.
        </p>
        <div>
          <h4>Most common Health Care Services Provided</h4>
          <ul>
            {[
              "Durable Medical Equipment (DME)",
              "Transportation",
              "Diagnostic",
              "Surgical",
            ].map((item, k) => (
              <li key={k}>{item}</li>
            ))}
          </ul>
          <div>Up to 20% soft cost</div>
        </div>
      </>
    ),
  },
];

export interface IServiceItem {
  image: string;
  title: string;
  subtitle: string;
  body: () => ReactElement;
}

export const ServiceItem: React.FC<IServiceItem> = ({
  image,
  title,
  subtitle,
  body,
}) => (
  <Stack.Item>
    <div className="card">
      <div className={`image ${image}`}></div>
      <div className="content">
        <h2 className="title">{title}</h2>
        {/* <p className="subtitle">{subtitle}</p> */}
        <div className="body">{body()}</div>
      </div>
    </div>
  </Stack.Item>
);

const Services = () => (
  <section className="services-section">
    <Stack>
      <Stack.Item>
        <div className="header">
          <h3>Available Services</h3>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p> */}
        </div>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal horizontalAlign="center" wrap>
          {items.map((item, i) => (
            <ServiceItem key={i} {...item} />
          ))}
        </Stack>
      </Stack.Item>
    </Stack>
  </section>
);

export default Services;
