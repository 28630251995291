import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faHandHoldingUsd, faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

import "./styles.scss";

const iconLarge: SizeProp = "6x";

export interface IQuickInfoItem {
  title: string;
  icon: IconProp;
  subheading: string;
  text: string;
}

const items: IQuickInfoItem[] = [
  {
    title: "Get Cash",
    icon: faHandHoldingUsd,
    subheading: "Secure up to $1M",
    text: "Get cash for your invoices up to $1M deposited directly into your bank account",
  },
  {
    title: "Pay Over Time",
    icon: faCalendarAlt,
    subheading: "Flexible payment schedule",
    text: "Choose a payment schedule",
  },
  {
    title: "Low Rates",
    icon: faCheck,
    subheading: "Competative rates",
    text: "We offer competative rates based on terms and creditworthiness",
  },
];

const InfoItem: React.FunctionComponent<IQuickInfoItem> = ({
  title,
  icon,
  subheading,
  text,
}) => (
  <Stack horizontalAlign="center">
    <Stack.Item grow>
      <div className="item-container">
        <div className="wrapper">
          <h3>{title}</h3>
          <FontAwesomeIcon icon={icon} size={iconLarge} />
        </div>
        <h4>{subheading}</h4>
        <div>{text}</div>
      </div>
    </Stack.Item>
  </Stack>
);

const QuickInfo = () => (
  <section className="quickinfo-section">
    <Stack horizontal>
      {items.map((item, key) => (
        <Stack.Item key={key} grow className="quickinfo-item">
          <InfoItem {...item} />
        </Stack.Item>
      ))}
    </Stack>
  </section>
);

export default QuickInfo;
