import React from "react";
import { Stack } from "@fluentui/react/lib/Stack";

import "./styles.scss";

export interface IItemProps {
  id: number;
  title: string;
  content: string;
  icon: string;
}

export const items: IItemProps[] = [
  {
    id: 1,
    title: "Apply Online Or Over The Phone",
    content: "Use our online form to submit an application or call us today.",
    icon: "icon1",
  },
  {
    id: 2,
    title: "Speak With A Financing Specialist",
    content:
      "One of our Financing Specialists will contact you and discuss how we can meet your needs.",
    icon: "icon2",
  },
  {
    id: 3,
    title: "Get Funded",
    content:
      "Once you're approved, you'll receive funding deposited directly into your bank account.",
    icon: "icon3",
  },
];

const SectionItem: React.FC<IItemProps> = ({ id, title, content, icon }) => (
  <Stack.Item grow>
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      className={`item-container item${id}`}
    >
      <Stack.Item grow className="left">
        {id === 2 ? (
          <div className={`icon ${icon}`}></div>
        ) : (
          <div className="info">
            <h4>{title}</h4>
            <p>{content}</p>
          </div>
        )}
      </Stack.Item>
      <Stack.Item grow className="middle">
        <div className="count">{id}</div>
        <div className="line"></div>
      </Stack.Item>
      <Stack.Item grow className="right">
        {id === 2 ? (
          <div className="info">
            <h4>{title}</h4>
            <p>{content}</p>
          </div>
        ) : (
          <div className={`icon ${icon}`}></div>
        )}
      </Stack.Item>
    </Stack>
  </Stack.Item>
);

const HowItWorksSection = () => (
  <section className="howitworks-section">
    <Stack horizontalAlign="center">
      <Stack.Item grow>
        <div className="header">
          <h3>How It Works</h3>
          <p>
            When you choose BRIC as your financing provider, you get a team of
            dedicated financing professionals to help you through the process of
            establishing a lending solution. We make it easy for you to work
            with us!
          </p>
        </div>
      </Stack.Item>
      <Stack.Item grow>
        <Stack>
          {items.map((item, key) => (
            <SectionItem key={key} {...item} />
          ))}
        </Stack>
      </Stack.Item>
    </Stack>
  </section>
);

export default HowItWorksSection;
